@import "variables"
.assignment-params
	&__row, &__column
		display: flex
		align-items: center
		justify-content: space-around
		font-size: 13px
		margin-bottom: 5px
		padding: 10px
		border-radius: 10px
		border: 1px dashed #ddd
	&__column
		flex-direction: column
		width: 450px
	&__actions
		display: flex
		width: 94px
	&__action
		flex-shrink: 0
		padding: 0
		margin-left: 10px
		width: 30px
		height: 30px
		border: 0
		opacity: 0.6
		color: $secondary-color
		transition: opacity .2s ease
		text-align: center
		line-height: initial
		font-size: 18px
		border-radius: 5px
		cursor: pointer
		&:disabled
			cursor: not-allowed
		&:hover
			opacity: 1
		&:focus
			outline: none
		&-label
			display: block
		&-add
			display: flex
			font-size: 22px
			line-height: inherit
			align-items: flex-end
			justify-content: center
			position: relative
