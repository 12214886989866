.dashboard__panel
	display: flex
	justify-content: flex-start
	margin-left: 10px

.dashboard__content
	display: table
	margin: 20px auto
	margin-bottom: 30px
	width: 100%

.dashboard__wrapper
	max-width: 1230px
	height: 100vh
	margin: 20px auto

.dashboard__notification
	font: 28px "SF-Semibold"
	opacity: .6
	justify-content: center
	align-items: center
	display: flex
	height: 100vh

.tile__wrapper
	border: 1px solid #d9d9d9

.tile
	position: relative
	float: left
	.tile__content
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		background-color: $white
		overflow: hidden
		display: block
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .05), 0 3px 1px -2px rgba(0, 0, 0, .08), 0 1px 5px 0 rgba(0, 0, 0, .08)
		border-radius: 3px
		box-sizing: border-box
		height: 100%
		.tile__content-header
			display: flex
			justify-content: space-between
			padding: 8px 15px
			background-color: #edf7fa
			color: #6d6e7a
			position: absolute
			width: 80%
			.fa-caret-down
				display: none
				cursor: pointer
				color: #25b3c6
				opacity: 0.7
				line-height: 1.2
			.fa-caret-down:hover
				opacity: 1

			.fa-caret-down:before
				font-size: 18px
			&--settings
				background-color: $white

		.tile__content-container
			padding: 20px 0 15px 0
			text-align: center
			white-space: nowrap
			color: #6d6e7a
			font: 48px "SF-bold"
			hr
				margin: 10px 0
			.tile__content-container-item
				line-height: 1
				font-size: 22px
				text-align: left
				padding: 0 0 0 15px
				&:first-child
					padding: 50px 0 0 15px
			.customer-invoices
				&:first-child
					font: 16px "SF-regular"
					display: block
				&:last-child
					font: 24px "SF-semiBold"
			.customer-text
				font: 13px "SF-medium"
				text-align: left
				margin-top: 5px
				margin-left: 15px
				span
					display: inherit
			.customer-number
				color: #30334d
				font-size: 14px
			.arrow
				position: absolute
				margin-top: 15px
				margin-left: 5px
				font-size: 20px
				&--decrease
					color: #e2121b
				&--increase
					color: #24c000
			&--double-width
				display: flex
				width: 100%
				img
					width: 90%
					height: 72%
					margin-left: 20px
				.double-width-item
					width: 50%

			&--double-height
				padding: 0
				hr
					margin: 15px 0
				.customer-text
					text-align: left
					margin: 20px 0 20px 10px
					padding-left: 10px
					line-height: 1.5
					border-left: 3px solid
					border-color: lightgray
					.customer-number
						font-weight: 800
						color: inherit
			&--whole
				padding: 0
				line-height: 4.5
			&--settings
				font-size: 14px
				width: 100px
				color: #95969e
				padding: .3em 0.2em
				margin-left: 10px
				margin-top: 5px
				padding-top: 35px

		.tile__content-footer
			font-size: $fsize-m
			padding: 0 15px
			color: #6d6e7a
			margin-top: -5.5em
			&--hide
				display: none
			&--settings
				padding: 5px
				width: 100px
				margin: 10px
				margin-top: 20px
				color: $white

	&--single
		width: 190px
		&:before
			padding-top: 100%

	&--double
		width: 394px
		img
			width: 90%
			margin-left: 3%
		&:before
			padding-top: 100%

	&--doubleWeight
		width: 394px
		.tile__content
			.tile__content-header
				width: 88%
		&:before
			padding-top: 48.3%

	&--doubleHeight
		width: 190px
		height: 394px
		.tile__content-container--double-height:nth-child(2)
			padding-top: 35px
		&:before
			padding-top: 100%

	&:before
		content: ""
		display: block

.tile__settings
	width: 120px
	height: 136px

// bargraph tile content
.bargraph-wrapper
	flex-direction: column
	justify-content: center
	align-items: center

.bargraph
	display: block
	position: relative
	width: 75%
	height: 125px
	margin: 1.1em 0 0
	padding: 0
	font-size: 9px
	tr,
	th,
	td
		position: absolute
		bottom: 0
		margin: 0
		padding: 0
		text-align: center

	tbody th
		bottom: -1.75em
		width: 60px

.bargraph__bar
	width: 40px
	border-bottom: none
	left: 10px
	background-color: #7fdbff
	border-color: transparent
	.bargraph__bar-value
		font-size: 10px
		margin-top: -10px
	p
		margin: 5px 0 0
		padding: 0
		opacity: .4

.bargraph__column
	&--1
		left: -8px
		z-index: 9
	&--2
		left: 40px
		z-index: 8
	&--3
		left: 88px
		z-index: 7
	&--4
		left: 136px
		z-index: 6
	&--5
		left: 184px
		z-index: 5
	&--6
		left: 232px
		z-index: 4

.bargraph__column:last-child
	border-right: none

.bargraph__rows
	position: relative
	top: -102px
	left: 2px
	width: 75%
	z-index: 1
	font-size: 10px
	font-family: "fira-sans-2", Verdana, sans-serif
	.bargraph__rows-item
		position: relative
		border-top: 1px dotted #C4C4C4
		width: 100%
		height: 50px
		p
			position: absolute
			left: -4em
			top: -0.8em
			margin: 0 0 0 0.5em

// gauge tile content
$baseFontSize: 20

@function rem($val)
	@return ($val/$baseFontSize)+rem

.gauge
	position: relative
	overflow: hidden
	display: block
	width: rem(200)
	height: rem(100)
	margin: rem(20) rem(14)
	margin-top: 40px

.gauge-wrapper
	padding: 10px

.gauge__circle
	position: relative
	display: block
	width: rem(200)
	height: rem(100)
	background: #7fdbff
	border-radius: 50% 50% 50% 50% / 100% 100% 0% 0%
	&::before
		content: ""
		position: absolute
		bottom: 0
		left: 50%
		z-index: 2
		display: block
		width: rem(140)
		height: rem(70)
		margin-left: rem(-70)
		background: #fff
		border-radius: 50% 50% 50% 50% / 100% 100% 0% 0%

.gauge__text
	width: 148px
	display: flex
	justify-content: space-between
	margin-top: -10px
	padding: 0 16px
	font: 10px "SF-regular"
	&--number
		margin-top: -45px
		font-size: 28px
		z-index: 11

.gauge__circle-mask
	position: absolute
	top: 0
	left: 0
	width: rem(200)
	height: rem(200)
	background: transparent
	transform: rotate(180deg) translate3d(0, 0, 0)
	transform-origin: center center
	backface-visibility: hidden
	transition: all .3s ease-in-out
	&:before
		content: ""
		position: absolute
		top: 0
		left: 0
		z-index: 2
		display: block
		width: rem(202)
		height: rem(102)
		margin: -1px 0 0 -1px
		background: #f2f2f2
		border-radius: 50% 50% 50% 50% / 100% 100% 0% 0%

.fixed
	background: transparent !important

.gridster-column, .gridster-row
	border-color: #d9d9d9 !important
