.app-spinner, .app-spinner-cw, .app-spinner-xero, .app-spinner-qb, .app-spinner-br
	position: fixed
	z-index: 999
	height: 1px
	width: 45px
	overflow: show
	margin: auto
	top: 0
	left: 0
	bottom: 0
	right: 0
	&:before
		content: ''
		display: block
		position: fixed
		top: 0
		left: 0
		width: 100%
		height: 100%
		background-color: rgba(0, 0, 0, 0.1)
	&:not(:required)
		font: 0/0 a
		color: transparent
		text-shadow: none
		background-color: transparent
		border: 0
		&:after
			content: ''
			display: block
			height: 50px
			width: 50px
			-webkit-animation: spinner .6s infinite linear
			-moz-animation: spinner .6s infinite linear
			-o-animation: spinner .6s infinite linear
			animation: spinner .6s infinite linear
			border-left: 6px solid rgba(0, 174, 239, 0.15)
			border-right: 6px solid rgba(0, 174, 239, 0.15)
			border-bottom: 6px solid rgba(0, 174, 239, 0.15)
			border-top: 6px solid rgba(0, 174, 239, 0.8)
			border-radius: 100%
.app-spinner-cw, .app-spinner-qb, .app-spinner-br
	&:before
		background-color: white

.app-spinner-qb
	&:before
		top: 40px

.app-spinner-xero
	position: relative
	&:before
		left: auto
		right: 0
		width: 482px

.app-spinner-int
	position: absolute
	top: -40px
	&:before
		background: none

@-webkit-keyframes spinner
	0%
		-webkit-transform: rotate(0deg)
		-moz-transform: rotate(0deg)
		-ms-transform: rotate(0deg)
		-o-transform: rotate(0deg)
		transform: rotate(0deg)

	100%
		-webkit-transform: rotate(360deg)
		-moz-transform: rotate(360deg)
		-ms-transform: rotate(360deg)
		-o-transform: rotate(360deg)
		transform: rotate(360deg)

@-moz-keyframes spinner
	0%
		-webkit-transform: rotate(0deg)
		-moz-transform: rotate(0deg)
		-ms-transform: rotate(0deg)
		-o-transform: rotate(0deg)
		transform: rotate(0deg)

	100%
		-webkit-transform: rotate(360deg)
		-moz-transform: rotate(360deg)
		-ms-transform: rotate(360deg)
		-o-transform: rotate(360deg)
		transform: rotate(360deg)

@-o-keyframes spinner
	0%
		-webkit-transform: rotate(0deg)
		-moz-transform: rotate(0deg)
		-ms-transform: rotate(0deg)
		-o-transform: rotate(0deg)
		transform: rotate(0deg)

	100%
		-webkit-transform: rotate(360deg)
		-moz-transform: rotate(360deg)
		-ms-transform: rotate(360deg)
		-o-transform: rotate(360deg)
		transform: rotate(360deg)

@keyframes spinner
	0%
		-webkit-transform: rotate(0deg)
		-moz-transform: rotate(0deg)
		-ms-transform: rotate(0deg)
		-o-transform: rotate(0deg)
		transform: rotate(0deg)

	100%
		-webkit-transform: rotate(360deg)
		-moz-transform: rotate(360deg)
		-ms-transform: rotate(360deg)
		-o-transform: rotate(360deg)
		transform: rotate(360deg)
