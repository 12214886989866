.context-menu
	width: 160px
	height: auto
	box-shadow: 0 0 20px 0 #ccc
	position: absolute
	display: block
	.context-menu__list
		list-style: none
		padding: 0
		margin: 0
		background: $white
		position: relative
		z-index: 5
		.context-menu__list-item:not(.separator)
			padding: 10px 7px
			cursor: pointer
			&:hover
				background: #f2f2f2
