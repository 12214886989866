.manual-tooltip
	font: 13px "SF-semiBold"
	background: rgba(0, 0, 0, 0.8)
	border-radius: 8px 8px 8px 0
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5)
	color: #FFF
	display: block
	margin-top: -20px
	padding: 2px
	right: 0
	opacity: 0
	position: absolute
	z-index: 999
	text-align: center
	transition: all .3s

.tooltip
	color: inherit
	span
		display: none
	&:hover
		position: relative
		span
			font: 13px "SF-semiBold"
			background: rgba(0, 0, 0, 0.8)
			border-radius: 8px 8px 8px 0
			box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5)
			color: #FFF
			display: block
			width: 210px
			margin-top: -50px
			margin-left: 60px
			padding: 0 7px
			position: absolute
			z-index: 999
			text-align: center

	&--invoice-link:hover, 	&--status-hidden:hover
		span
			position: fixed
			width: auto
			margin-top: 0
			margin-left: 40px
	&--invoice-date:hover
		span
			position: fixed
			width: auto
			padding: 3px
			margin-top: -40px
			margin-left: 70px

	&--dashboardBar:hover
		span
			width: auto
			margin-top: -10px
			margin-left: 20px

	&--transaction-type:hover
		span
			position: fixed
			width: 65px
			margin-top: -22px
			margin-left: 20px

.tooltip--feeds:hover,
.tooltip--feeds:hover,
.tooltip--tile:hover,
.tooltip--tile_1:hover,
.tooltip--export:hover,
.tooltip--export_1:hover,
.tooltip--export_2:hover
	span
		font-size: 14px
		margin-top: -45px
		width: 448px
		padding: 5px
		margin-left: -450px
		border-radius: 8px 8px 0 8px

.tooltip--wise-1:hover, .tooltip--wise-2:hover, .tooltip--wise-3:hover, .tooltip--wise-4:hover
	span
		margin-top: -70px !important
		width: 380px !important
		margin-left: 15px !important

.tooltip--wise-1:hover
	span
		margin-top: -40px !important

.tooltip--wise-4:hover
	span
		margin-top: -85px !important
		width: 350px !important

.tooltip--tile, .tooltip--tile_1
	margin-left: 2px
	&:hover
		span
			margin-top: -40px
			width: 245px
			margin-left: 10px
			border-radius: 8px 8px 8px 0

.tooltip--tile_1
	&:hover
		span
			width: 400px

.tooltip--export_1,
.tooltip--export_2
	margin-left: 2px
	&:hover
		span
			word-break: break-word
			margin-top: -60px
			width: 350px
			margin-left: -350px

.tooltip--export_1
	&:hover
		span
			margin-top: -45px

.tooltip--incorporated, .tooltip--exemption
	margin-left: 2px
	&:hover
		span
			text-align: left
			padding: 10px
			margin-top: -210px
			width: 380px
			margin-left: 15px
			border-radius: 8px 8px 8px 0

.tooltip--exemption
	&:hover
		span
			margin-top: -120px

.tooltip--xero:hover
	span
		margin-left: 15px !important
		margin-top: -40px !important

.tooltip--placeholder
	&:hover
		span
			width: 200px
			padding: 4px
			margin-top: 5px
			margin-left: -205px
			border-radius: 8px 0px 8px 8px
