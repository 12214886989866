.login
	width: 100%
	height: 100%
	overflow: auto
	display: -webkit-box
	display: -ms-flexbox
	display: flex
	position: absolute
	&--customize
		width: 812px
		height: 275px
		margin: 0 auto
		position: relative
		.login__form
			transform: scale(.6)
			input
				cursor: default
		.login__item
			&--first
				width: 30%
				padding: 0
			&--second
				width: 70%

.login__item
	-webkit-flex-direction: column
	-ms-flex-direction: column
	flex-direction: column
	display: flex
	justify-content: center
	&--first
		width: 25%
		padding: 0 40px
		background-color: white
	&--second
		width: 75%

	.login__image-wrapper
		height: 100vh
		position: relative

	.login__item-image
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: #d9d9d9 url(/assets/img/man_on_phone.png) no-repeat center center
		background-size: cover

.login__form
	overflow: hidden
	padding: 1px
	width: auto
	max-width: 430px
	margin: 0 auto
	backface-visibility: hidden
	-webkit-backface-visibility: hidden
	transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1)
	.login__form-password
		border: 0
		padding: 0
		float: right
		top: -17.2em
		color: #8a8a96
		opacity: 0.8
		display: flex
		.login__form-password-title
			min-width: 40px
			font-size: 15px
			vertical-align: top
			margin-left: 5px
			&:after
				content: 'Show'
		.fa
			font-family: FontAwesome
			font-size: 18px
		.fa-eye:before
			content: '\f06e'
		&--hide
			opacity: 1
			.fa-eye:before
				content: '\f070'
			.login__form-password-title:after
				content: 'Hide'
		&:hover
			opacity: 1

	.login__form-title
		text-align: center
	.login__form-content
		margin: 15px auto 20px
		.btn:active
			box-shadow: none
		input
			padding: 0.8em 1em
			font-size: 17px
		.login__form-label
			line-height: 2.5
			color: #6d6e7a
			font-weight: 500
			font-size: 15px
			width: auto
			&--pw-forgot
				line-height: 2
			&--alert
				background-color: #F8D0C8
				color: inherit
				padding: 10px
				border-radius: 5px
				.fa
					margin: 0 10px
					font-size: 25px
					display: inline-block
					vertical-align: sub
					color: indianred
	&--pw-forgot
		margin-top: -25em
	&.inactive
		transform: rotateX(180deg)

.login__bottom
	width: 100%
	padding-top: 2em
	.login__bottom-link
		line-height: 2.5
		display: flex
		justify-content: center
		margin-top: 15px
		font-size: 16px
		opacity: 0.8
	.login__bottom-link:hover
		opacity: 1
	.login__bottom-box
		width: inherit
		padding: 0.7em
		font-weight: 500
		font-size: 17px
