$size: 60px
.page
	margin: 0 auto

	.page__panel
		padding: 0 25px
		background: $panel-color
		-webkit-box-shadow: 0 1px 0 0 rgba(1, 144, 163, 0.8)
		-moz-box-shadow: 0 1px 0 0 rgba(1, 144, 163, 0.8)
		box-shadow: 0 1px 0 0 rgba(1, 144, 163, 0.8)
		color: #30334d

		.page__panel-body
			display: grid
			display: -ms-grid
			padding-bottom: 10px
			justify-content: flex-start
			grid-template-columns: 350px auto
			-ms-grid-columns: 350px auto
			margin: 0 auto
			.page__panel-body-item
				word-wrap: break-word
				margin-top: 10px
				.page__panel-body-title
					margin: 0
					font: 24px "SF-Regular"
					&--strikeout
						text-decoration: line-through
				&:nth-child(2)
					display: inherit
					grid-template-columns: 170px auto
					-ms-grid-columns: 170px auto

			.page__panel-body-item > :nth-child(2)
				-ms-grid-column: 2

			.page__panel-body-column
				display: inherit
				border-right: 2px solid #23CDDB
				.page__panel-body-column-text, .page__panel-body-column-status
					font: 14px "SF-Regular"
					&--code
						color: #31879d
					&--misc
						font-size: $fsize-l
						color: #30334d
					&--phone
						font-size: 22px
						color: #30334d
					&--status
						margin-top: 5px

				.page__panel-body-column-status
					border: 0
					outline: none
					margin: -5px 0 0 5px
					position: absolute
					padding: 2px 8px
					border-radius: 10px
					color: #444
					background-color: #5dcede
					cursor: pointer
					box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)
					transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1)
					&--lock, &--unlock
						.fa
							font: 19px FontAwesome
							color: #31879d
							margin-left: 5px
					&--lock
						.fa-lock:before
							content: '\f023'
					&--unlock
						.fa-lock:before
							content: '\f13e'

				.page__panel-body-column-status:hover
					box-shadow: 0 0 5px rgba(0, 0, 0, 0.25), 0 2px 5px rgba(0, 0, 0, 0.22)
					.fa
						color: #30334d

			.page__panel-body-column:last-child
				border: 0
				padding-left: 40px
				-ms-grid-columns: 300px

		.page__panel-body > :nth-child(2)
			-ms-grid-column: 2

	.page__navigation
		padding-top: 17px
		padding-bottom: 8px
		margin: 0 auto
		.page__navigation-tabs
			display: flex
			list-style-type: none
			white-space: nowrap
			margin-bottom: 0
			margin-top: 0
			padding: 0
			width: 100%

			.page__navigation-tabs--item
				cursor: pointer
				font-weight: 500
				.page__navigation-tabs--link
					padding: 12px 15px
					color: $white
					border-radius: 2px
					border-bottom-right-radius: 0
					border-bottom-left-radius: 0

				.page__navigation-tabs--link.active
					background-color: #f4f4f4
					color: #30334d
					font-weight: 600
					border: 1px solid #14a1b6
					border-bottom: 1px solid #f4f4f4

				.page__navigation-tabs--link:hover
					color: #30334d
					opacity: 1
					filter: alpha(opacity=50)

	.page__content, .page-portal__content
		padding: 0 25px
		padding-top: 20px
		margin: 0 auto

	.page-portal__content
		padding-top: 1px

.page__operation-progress
	.page__operation-progress-footer
		display: flex
		justify-content: flex-start
		padding-top: 15px
		margin: 0 auto
	.page__operation-progress-button
		margin-left: 20px
		&:first-child
			margin-left: 0
	.container-process
		width: 100%
		height: 30px
	.process-message
		margin: 5px 0
	.process-message_gray
		color: #bfbebe
		margin: 0

.transaction__period-selector
	width: 100%
	display: flex
	padding-left: 18px
	margin-top: -10px
	margin-bottom: 10px
	align-items: center

.page__content-setting
	display: flex
	justify-content: space-between
	padding: 15px 0
	padding-top: 0
	height: 36px
	padding-left: 18px
	.page__content-setting-item
		.btn
			margin-right: 20px
			width: 36px
			height: 36px
		.setting-item-label
			display: inline-block
			line-height: 2
			font-size: 14px
			margin-right: 10px
		.setting-item-select
			position: relative
			margin-right: 15px
			select
				padding: .72em 1em
			.ng-select .ng-select-container
				width: 180px !important
				.ng-value-container
					padding: .5em 0
				.ng-value, .ng-input > input
					padding-left: 10px !important

		.filter-icon
			margin-right: 10px
			font-size: 1.7em
			cursor: pointer
			vertical-align: middle
			line-height: 0.9
			font-family: FontAwesome
			margin-top: -3px
			&--active
				background-color: #666666
				-webkit-background-clip: text
				-moz-background-clip: text
				background-clip: text
				color: transparent
				text-shadow: rgba(255, 255, 255, 0.5) 0 3px 3px
			&--add
				margin-right: 5px
			&--add:after, &--reset:after
				font-size: 0.8em
				color: $primary-color
			&--add:after
				content: '\f00c' !important
				margin-left: -13px
			&--reset:after
				content: '\f00d' !important
				margin-left: -11px
				color: $secondary-color
			&:before
				content: '\f0b0'

		.filter-input
			width: 200px
			line-height: 1.5

		&--item-1, &--item-1-service
			display: flex
			align-items: center
		&--item-1-service
			.setting-item-select
				select
					padding: .72em 1em
					padding-right: 1.5em
		&--usage
			margin-bottom: 10px
			display: flex
			align-items: center


	//prevent change btn size for datepicker on invoicing page
	.btn-inherit
		.btn
			margin-right: 0 !important
			width: inherit !important
			height: inherit !important

	&--padding
		padding-top: 20px
	&--f-end
		padding-top: 15px
		justify-content: flex-end

.page__content-setting-item:last-child
	margin-right: 17px
