.chips
	border-radius: 5px
	background-color: transparent
	display: inline-block
	width: auto

.chip
	padding: 0 10px
	height: 25px
	font-size: 13px
	line-height: 25px
	float: left
	margin: 2px
	border: 1px solid #ddd
	border-radius: 3px
	background: #ecf5f8
