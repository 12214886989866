.form__email
	-webkit-backface-visibility: hidden
	transition: all .6s cubic-bezier(0.645, 0.045, 0.355, 1)
	position: absolute
	margin-left: -512px !important
	left: 50%
	.form__title
		font-size: 18px
		padding: 0
	&--inactive
		transform: rotateX(90deg)

.email-template
	width: 100%
	height: 570px
	overflow: auto
	display: -webkit-box
	display: -ms-flexbox
	display: flex
	padding-bottom: 3em
	&__item
		-webkit-flex-direction: column
		-ms-flex-direction: column
		flex-direction: column
		display: flex
		&:first-child
			width: 27%
			padding-left: 15px
			background-color: white
		&:nth-child(2)
			width: 3%
			background: transparent
		&:last-child
			width: 70%
	&__subject
		padding: 15px
		display: flex
		justify-content: space-between
		background: white
		border-bottom: 1px solid #e5e5e5
		button
			width: inherit
			height: 37px
			i
				margin-left: 5px
	&__input
		width: 87%
		padding: 9px 0
		font-size: 16px
		background: transparent
		border: none
		outline: none
		border-radius: 0
		border-bottom: 1px solid transparent
		transition: .5s all
		&:focus
			border-bottom-color: $secondary-color
		&.ng-invalid
			border-bottom-color: indianred
		&.ng-invalid:focus
			outline: none !important
			-webkit-box-shadow: none !important
			box-shadow: none !important
	&__area
		font-size: 16px
		padding: 15px
		width: 100%
		height: 100%
		outline: none
		resize: none
		border-radius: 0
		border: 1px solid transparent
		transition: .5s all
		&:hover
			border-color: transparent
		&.ng-invalid
			border: 1px solid indianred
		&:hover.ng-invalid
			border-color: indianred
	&__body
		height: 73%
		width: 100%
		background: white

	&__placeholder
		width: 95%
		height: auto
		overflow-y: auto
		max-height: 90%
		&-title
			padding-top: 25px
			&:last-child
				padding-top: 0
		&-item
			width: 100%
			max-width: 215px
			margin: 5px 0
			padding: 5px
			display: flex
			align-items: center
			justify-content: space-between
			border: 1px solid #e5e5e5
			border-radius: 3px
			background: #e5e5e5
			cursor: pointer
			&:last-child
				margin-bottom: 30px
	&__panel
		background-color: #dcf0f5
		padding: 15px
		display: flex
		justify-content: space-between
		.email-template__group
			display: flex
			justify-content: space-between
			width: 170px
			button
				width: 70px
				height: 37px
				padding: 0

.email-template__switch, .cw-filter__switch
	display: flex
	margin: 10px 0
	.email-template__switch-title
		margin: 0 10px
		font-size: 15px
		margin-bottom: -8px
		transition: .5s all
		border-bottom: 1px solid transparent
		&:first-child
			margin-left: 0
		&--active
			display: inline-block
			border-bottom-color: $secondary-color
	.switch-medium
		width: 40px
		height: 20px
		min-width: 40px
		background-color: white
		small
			width: 20px
			height: 20px
			right: calc(100% - 20px)

.customer__switch
	display: flex
	top: 3px
	right: 25px
	position: relative
	.customer__switch-title
		position: absolute
		font-size: 10px
		pointer-events: none
		margin: 0
		z-index: 2
		top: 7px
		color: white
		-webkit-touch-callout: none /* iOS Safari */
		-webkit-user-select: none /* Safari */
		-moz-user-select: none /* Firefox */
		-ms-user-select: none /* Internet Explorer/Edge */
		user-select: none
		&--active
			left: 10px
		&--inactive
			right: 6px
		&--check
			visibility: inherit
		&--uncheck
			visibility: hidden

.customer__switch
.switch-medium
	width: 68px
	height: 28px
	background-color: $primary-color
	small
		right: calc(100% - 25px)
		width: 25px
		height: 25px
		top: 1.5px
