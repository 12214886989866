$body-background: #f4f4f4
$primary-color: #00a9c0
$secondary-color: #f55a24

/*status color*/
$color-warning: #F6C342
$color-success: #8EB021
$color-success-custom: #349aed
$color-error: #D04437
$color-default: lightgrey

$panel-color: #22d2df
$panel-color: -moz-linear-gradient(45deg, #22d2df 1%, #29b1c4 100%)
$panel-color: -webkit-linear-gradient(45deg, #22d2df 1%,#29b1c4 100%)
$panel-color: linear-gradient(45deg, #22d2df 1%,#29b1c4 100%)

$white: #fff
$fsize-s: 13px
$fsize-m: 14px
$fsize-l: 15px
