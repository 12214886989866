.grid, .grid--normal
	width: 100%
	max-width: 100%
	margin-top: 5px
	background-color: $body-background
	border-radius: 3px

	.grid__header, .grid__body-row
		display: grid
		padding: 0 8px
		display: -ms-grid
	.grid__header
		padding-top: 13px
		border-top: 1px solid rgba(0, 0, 0, 0.1)
		.grid__header-cell
			padding: 0 2.5px
			font-size: $fsize-m
			line-height: 2.1
			box-sizing: content-box
			color: #6d6e7a
			cursor: pointer
			.grid__header-cell--sorting
				margin-left: 5px
			& > .grid__header-cell
				padding: 0

	.grid__body
		padding-top: 12px
		.grid__body-row
			background: $white
			margin: 2.5px 0
			border-radius: 2px
			border-top: 1px solid $body-background
			border-bottom: 1px solid $body-background
			.grid__body-row-cell
				word-wrap: break-word
				padding: 13px 2.5px
				font-size: $fsize-s
				line-height: 2.1
				color: #1f2125
				.grid__body-row-cell--double
					.grid__body-row-cell--double-title
						color: inherit
						display: block
					.grid__body-row-cell--double-subtitle
						color: #6d6e7a
					.grid__body-row-cell--double-subtitle_
						color: #1f2125
				.grid__body-row-cell--status
					.status
						text-align: center
				&--number
					text-align: right
				.invoice-link
					width: 100px
					float: left
					cursor: pointer
				.invoice-email
					float: right
					margin-right: 10px
					margin-top: 3px
					height: 20px
				.fa-eye
					transition: all 0.2s
					opacity: 0
					cursor: pointer
					color: #444
					margin: 0 5px
					&:before
						font-size: 15px
				.fa-pause
					color: rgb(199, 199, 199)
					float: right
					margin: 7px
				.fa-spin
					margin-left: 5px
			&--empty
				padding: 13px
				display: flex
				justify-content: center
		.grid__body-row:hover
			-webkit-box-shadow: 0 0 10px 1px rgba(176,228,235,1)
			-moz-box-shadow: 0 0 10px 1px rgba(176,228,235,1)
			box-shadow: 0 0 10px 1px rgba(176,228,235,1)
			transition: all 0.2s
			background-color: #f8fdfd
			cursor: pointer
			.fa-eye
				opacity: 1
			.grid__body-row-cell
				text-decoration: underline

	.grid__footer
		.grid__page-size
			float: left
			margin: 15px 0

			.grid__page-size-label
				color: #6d6e7a
				font-weight: 500

			.grid__page-size-select
				width: 60px
				margin: 0 15px

	.checkbox-label
		line-height: 2.1

	.grid__body-row > :nth-child(2)
		-ms-grid-column: 2
	.grid__body-row > :nth-child(3)
		-ms-grid-column: 3
	.grid__body-row > :nth-child(4)
		-ms-grid-column: 4
	.grid__body-row > :nth-child(5)
		-ms-grid-column: 5
	.grid__body-row > :nth-child(6)
		-ms-grid-column: 6
	.grid__body-row > :nth-child(7)
		-ms-grid-column: 7
	.grid__body-row > :nth-child(8)
		-ms-grid-column: 8
	.grid__body-row > :nth-child(9)
		-ms-grid-column: 9
	.grid__body-row > :nth-child(10)
		-ms-grid-column: 10
	.grid__body-row > :nth-child(11)
		-ms-grid-column: 11
	.grid__body-row > :nth-child(12)
		-ms-grid-column: 12
	.grid__body-row > :nth-child(13)
		-ms-grid-column: 13

	.grid__header > :nth-child(2)
		-ms-grid-column: 2
	.grid__header > :nth-child(3)
		-ms-grid-column: 3
	.grid__header > :nth-child(4)
		-ms-grid-column: 4
	.grid__header > :nth-child(5)
		-ms-grid-column: 5
	.grid__header > :nth-child(6)
		-ms-grid-column: 6
	.grid__header > :nth-child(7)
		-ms-grid-column: 7
	.grid__header > :nth-child(8)
		-ms-grid-column: 8
	.grid__header > :nth-child(9)
		-ms-grid-column: 9
	.grid__header > :nth-child(10)
		-ms-grid-column: 10
	.grid__header > :nth-child(11)
		-ms-grid-column: 11
	.grid__header > :nth-child(12)
		-ms-grid-column: 12
	.grid__header > :nth-child(13)
		-ms-grid-column: 13

.grid--condensed
	.grid__body
		.grid__body-row
			border-radius: 5px
			margin: 1.5px 0
			.grid__body-row-cell
				padding: 4px 2.5px

	.grid__type-icon
		&--one-off:before
			left: 4px
		&--ebill:before
			left: 3px
		&--recurring:before
			left: 3px
		&--imported:before
			left: 5px

.grid--period
	.grid
		margin-top: 0
		.grid__header
			padding-top: 0
			border-top: none

.grid__status
	display: flex
	margin: 5px 0

.grid__status-icon, .grid__type-icon
	border-radius: 50%
	width: 35px
	height: 35px
	position: relative
	.fa
		position: absolute
		color: white
		font-size: 20px
		margin-left: 8px
		margin-top: 8px
	&--link
		background-color: $secondary-color
	&--hidden
		background-color: #164f86
	&--small
		width: 28px
		height: 28px
		.fa
			font-size: 15px
			margin-left: 7px
			margin-top: 7px

.grid__status-icon
	width: 30px
	height: 30px
	&:nth-child(2)
		margin-left: 5px
	.fa
		font-size: 18px
		margin-left: 7px
		margin-top: 7px

.grid__type-icon
	display: inline-block
	line-height: normal
	margin-right: 5px
	width: 15px
	height: 15px
	color: white
	&:before
		position: inherit
	&--imported
		background-color: $color-success
		&:before
			content: 'I'
			left: 6px
	&--one-off
		background-color: $color-warning
		&:before
			content: '1'
			left: 5px
	&--recurring
		background-color: $color-error
		&:before
			content: 'R'
			left: 4px
	&--ebill
		background-color: #00CED1
		&:before
			content: 'E'
			left: 4px

.grid__type-icon--kit
	background-color: $secondary-color
	&:before
		content: 'K'
		left: 4px

.grid__header-cell--number
	text-align: right

.files__row-action, .integration__row-action, .charge__row-action, .users__row-action, .email-template__row-action
	border: none
	background: 0
	cursor: pointer
	outline: none
	opacity: 0.7
	transition: 0.1s all
	color: #444444
	.fa, .fa-eye
		width: 14px
		color: $secondary-color
		margin-right: 6px
		font-size: 14px
		text-align: center
	.fa-eye
		opacity: 0.7 !important

.email-template__row-action
	transition: none
	&:hover
		transition: 0.1s all
		opacity: 1

.files__row-action:hover, .integration__row-action:hover, .charge__row-action:hover, .users__row-action:hover
	opacity: 1
	.fa-eye
		opacity: 1 !important

.cell-wrap-text
	white-space: normal !important

.ag-theme-material
	background: transparent
	.ag-loading
		justify-content: center !important
	.ag-loading-icon
		display: none
	.ag-loading-text:after
		content: '...'
	.ag-root-wrapper
		min-height: 340px
		background: transparent
	.ag-overlay-wrapper
		background: rgba(255, 255, 255, .5)
		padding-top: 30px !important
	.ag-cell__double
		.ag-cell__double-item
			color: #1f2125 !important
			display: block
		.ag-cell__double-item_
			color: #6d6e7a
			display: flex
			span:first-child
				min-width: 33px
			.ag-cell__double-item
				margin-left: 5px

	.ag-body-container, .ag-header-row
		background: #f4f4f4 !important

	.ag-header
		background: transparent
		border-bottom: none !important

	.ag-header-row
		border-width: 0 0 0 !important
		border-top: 1px solid rgba(0, 0, 0, 0.1) !important
		&:nth-child(2)
			background: white !important
			.ag-header-cell
				cursor: default

	.ag-floating-filter-button
		float: left !important
	.ag-paging-panel
		border-top: none

	.ag-header-cell
		font-size: 14px
		font-weight: 400
		cursor: pointer
		background: transparent
		padding-right: 10px !important
		padding-left: 9px !important

	.ag-header-cell-label
		justify-content: space-between

	.ag-cell
		font-size: 13px
		line-height: 37px !important
		border: none !important
		border-bottom: 1px solid #f4f4f4 !important
		border-top: 1px solid #f4f4f4 !important
		word-break: break-all !important
		padding-right: 9px !important
		padding-left: 9px !important

	.ag-cell-charges
		text-decoration: none !important
		padding-right: 0
		line-height: 28px !important
	.ag-cell-focus
		border-color: #f4f4f4 !important

	.ag-cell-inline-editing
		padding: 0 !important
		padding-left: 10px !important
		height: 37px !important
		box-shadow: none !important
	.ag-cell-invoice
		width: inherit
		.fa-pause
			color: #c7c7c7
			float: right
			margin: 12px 0
			margin-right: 10px
		.fa-eye, .fa-download
			transition: all 0.2s
			float: right
			opacity: 0
			cursor: pointer
			color: #444
			margin: 10px 0
			margin-right: 10px
			&:before
				font-size: 15px
		.fa-spin
			float: right
			margin: 10px 0
			margin-right: 10px
		&:hover
			.fa-eye, .fa-download
				opacity: 1
			.invoice-link
				text-decoration: underline

	.cell-check
		color: $primary-color

	.ag-menu
		z-index: 1
		.ag-picker-field-wrapper
			margin-bottom: 5px
			&:focus
				box-shadow: none !important
		input[type='text']
			border-bottom: 1px solid #ccc !important
		.ag-radio-button-input-wrapper
			input
				-webkit-appearance: radio !important
				opacity: 1 !important

			&::after
				display: none
			&:focus-within
				box-shadow: none !important
			&:active
				box-shadow: none !important

	.ag-row
		border-radius: 4px
		border-color: #f4f4f4 !important
		background: white

	.ag-row-selected
		background-color: white !important

	.ag-row-hover
		transition: .1s all !important
		background-color: #f8fdfd !important
		cursor: pointer
		> .ag-cell
			text-decoration: underline

	.invoice-link
		min-width: 110px
		float: left
		cursor: pointer

	.ag-floating-bottom-container
		pointer-events: none
		a
			color: #1f2125 !important
			text-decoration: none !important
		.grid__type-icon
			display: none !important
		.invoice-email
			display: none !important

	.ag-filter input[type="radio"]
		display: initial

	.invoice-email
		float: right
		margin-top: 7px
		height: 20px
	.ag-cell-inline-editing
		padding: 0 !important
		padding-left: 10px !important
		height: 37px !important
		box-shadow: none !important

	.ag-cell-checkbox
		.checkbox, .checkbox--checked
			input[type="checkbox"].checkbox-label
				margin-top: 7px !important

	.compare--new, .compare--new:hover
		background-color: #d2ecd2 !important
	.compare--deleted, .compare--deleted:hover
		background-color: #fda1a1 !important

	.compare--new:nth-child(odd)
		background-color: #bdd4bd !important
	.compare--deleted:nth-child(even)
		background-color: #e39090 !important

	.ag-row:not(.ag-row-first)
		border-width: 0 !important

	.ag-header-cell, .ag-cell
		font-family: SF-medium

	.ag-cell-wrapper
		cursor: pointer

	.ag-details-row
		padding: 10px 20px

		.ag-header-cell-menu-button .ag-icon-menu
			display: block
			height: 37px
		.ag-header-cell
			line-height: 37px !important
		.ag-status-bar
			display: none

	.ag-cell-checkbox
		.checkbox, .checkbox--checked
			input[type="checkbox"].checkbox-label
				margin-top: 7px !important

	input[type='text']
		border: none
		height: 30px !important

	.ag-checkbox-input
		cursor: pointer

	.ag-icon-asc
		color: transparent !important
		background-image: url(/assets/icons/sort-up.svg) !important

	.ag-icon-desc
		color: transparent !important
		background-image: url(/assets/icons/sort-down.svg) !important

	.ag-grid-sort-none
		.customSortDownLabel, .customSortUpLabel
			display: none

	.ag-filter-body-wrapper
		padding: 8px

	.ag-filter .ag-filter-select
		margin: 0 !important

	.ag-icon
		display: inline
	.ag-floating-filter-button span
		cursor: pointer !important
	.ag-floating-filter-input, input
		padding-left: 0
		border-radius: 0
	.ag-primary-cols-filter-wrapper input
		height: initial !important
		padding-bottom: 4px !important
	.ag-primary-cols-filter-wrapper input:focus, input[type="text"]:focus, .ag-filter input[type="date"]:focus
		border-color: $secondary-color !important
	.tagCell
		.ng-clear-wrapper
			display: none

	.ag-cell-numeric
		text-align: right !important

	.ag-checkbox-input-wrapper:focus-within,
	.ag-checkbox-input-wrapper:active
		box-shadow: none !important

	.ag-checkbox-input-wrapper.ag-indeterminate::after,
	.ag-checkbox-input-wrapper::after
		color: #E3E4E4

	.ag-checkbox-input-wrapper.ag-checked::after
		color: $primary-color

	.ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value)
		height: 37px !important

	.ag-cell-header-checkbox
		.ag-cell-label-container
			display: none
	.ag-column-hover
		background: transparent !important

	.ag-cell-header-numeric
		.ag-header-cell-label
			justify-content: flex-end !important
			margin-right: 5px

.ag-theme-balham
	.ag-root-wrapper-body
		min-height: 310px

	.ag-button
		background: transparent
		border-radius: 4px
		border: 1px solid lightgray
		color: grey
		font-size: 15px
		height: 24px
		padding: 0
		width: 60px
		cursor: pointer
		margin-top: 5px
		i
			vertical-align: text-top


.enterprise
	.ag-header
		height: 56px !important
		min-height: 56px !important
	.ag-details-row
		.ag-header
			height: 37px !important
			min-height: 37px !important

.tag
	display: flex
	align-items: center
	max-width: 350px
	justify-content:  space-between
	button
		margin-left: 15px
		width: 100px

.grid__info
	clear: none
	float: left
	margin: 20px 0
	margin-bottom: 30px
	padding-left: 10px
	color: #6d6e7a

.invoices-selected
	color: #6d6e7a
	opacity: .8
	align-items: center
	font: 14px "SF-semiBold"
	span
		opacity: 1
		margin-left: 5px
		font-size: 16px

.grid__paging
	margin: 15px 0
	float: right
	width: auto
	max-width: none
	display: inline-block
	border-radius: 0
	span
		color: rgba(0,0,0,.54)
	.grid__paging-button
		box-sizing: border-box
		display: inline-block
		min-width: 1.5em
		padding: 5px 13px
		text-align: center
		text-decoration: none
		cursor: pointer
		transition: all 0.2s
		&--current
			color: #22D1DE
		&--disabled
			color: #ccc
