.navbar
  display: flex
  justify-content: center
  align-self: flex-end
  margin-bottom: 0

  .navbar__item
    box-sizing: border-box
    position: relative
    cursor: pointer
    border-bottom: 2px solid transparent
    transition: all 0.2s

    .navbar__item-group-link
      text-decoration: none
      display: block
      padding: 10px 25px
      padding-top: 13px
      color: #fff
      cursor: pointer
      font: 15px "SF-medium"
      .fa-caret-down:before
        margin-left: 10px

    .navbar__item-group
      width: auto
      list-style: none
      margin-top: -10px
      padding: 0
      position: absolute
      left: 0
      top: 50px
      z-index: 22
      max-height: 0
      overflow: hidden
      -webkit-transform: perspective(400px) rotate3d(1,0,0,-90deg)
      -webkit-transform-origin: 50% 0
      -webkit-transition: 350ms
      -moz-transition: 350ms
      -o-transition: 350ms
      transition: 350ms
      background-color: #30334d

      .navbar__subitem
        text-decoration: none
        .navbar__link
          color: #fff
          display: block
          font-size: 15px
          line-height: 1
          padding: 15px 15px 10px 15px
          text-decoration: none
          white-space: nowrap

        .navbar__link:hover
          color: #30d6e5
        &--custom
          padding-bottom: 0 !important

      .navbar__subitem:last-child
        padding-bottom: 10px

    .navbar__item-group--left
      right: 0
      left: auto

    &--active
      border-color: #fff

  .navbar__item:hover .navbar__item-group
    display: block
    max-height: 1000px
    -webkit-transform: perspective(400px) rotate3d(0,0,0,0)
    opacity: 0.97
    border-bottom-right-radius: 3px
    border-bottom-left-radius: 3px

  .navbar__item:after .navbar__item-group
    clear: both
