.ng-select
	&.ng-select-opened
		top: 0
		> .ng-select-container
			&:hover
				box-shadow: none
			.ng-arrow
				top: -2px
				border-color: transparent transparent #999
				border-width: 0 5px 5px
				&:hover
					border-color: transparent transparent #666
		&.ng-select-bottom > .ng-select-container
			border-bottom-right-radius: 0
			border-bottom-left-radius: 0
		&.ng-select-top > .ng-select-container
			border-top-right-radius: 0
			border-top-left-radius: 0
	&.ng-select-disabled > .ng-select-container
		background-color: #f9f9f9
	.ng-has-value .ng-placeholder
		display: none
	.ng-select-container
		background-color: #fcfcfc
		border-radius: 4px
		border: 1px solid #ddd
		min-height: 32px
		-webkit-box-align: center
		-webkit-align-items: center
		-ms-flex-align: center
		align-items: center
		&:hover
			box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06)
		.ng-value-container
			-webkit-box-align: center
			-webkit-align-items: center
			-ms-flex-align: center
			align-items: center
			.ng-value
				color: #6d6e7a
				//padding: .5em 1em
			.ng-placeholder
				color: #aaa
	&.ng-select-single .ng-select-container
		//height: 32px
		.ng-value-container .ng-input
			left: 0
			padding-right: 50px
			top: 5px
			margin-top: -4px
			padding-left: 0
			color: #30334d
	&.ng-select-multiple
		&.ng-select-disabled > .ng-select-container .ng-value-container .ng-value
			background-color: #f9f9f9
			border: 1px solid #e3e3e3
			.ng-value-label
				padding: 0 5px
		.ng-select-container .ng-value-container
			padding-left: 7px
			padding-top: 5px
			.ng-value
				font: 1em/1.6 "SF-medium"
				// font-weight: 900
				margin-right: 5px
				margin-bottom: 5px
				background-color: #f5faff
				border-radius: 2px
				border: 1px solid rgba(0, 169, 192, .4)
				-webkit-background-clip: padding-box /* for Safari */
				background-clip: padding-box /* for IE9+, Firefox 4+, Opera, Chrome */
				&.ng-value-disabled
					background-color: #f9f9f9
					border: 1px solid #e3e3e3
					.ng-value-label
						padding-left: 5px
				.ng-value-label
					display: inline-block
					padding: 0 5px 0 5px
				.ng-value-icon
					display: inline-block
					padding: 0 5px
					&:hover
						background-color: #d8eafd
					&.left
						border-right: 1px solid rgba(0, 169, 192, .4)
					&.right
						border-left: 1px solid rgba(0, 169, 192, .4)
			.ng-input
				padding-bottom: 3px
				padding-left: 3px
			.ng-placeholder
				top: 5px
				padding-bottom: 5px
				padding-left: 3px
	.ng-clear-wrapper
		color: #999
		z-index: 10
		.ng-clear:hover
			color: #D0021B
	.ng-spinner-zone
		padding-right: 5px
		padding-top: 5px
	.ng-arrow-wrapper
		padding-right: 5px
		width: 25px
		&:hover .ng-arrow
			border-top-color: #666
		.ng-arrow
			border-color: #999 transparent transparent
			border-style: solid
			border-width: 5px 5px 2.5px

.ng-dropdown-panel
	background-color: #fff
	border: 1px solid #ddd
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06)
	&.ng-select-bottom
		top: 100%
		border-bottom-right-radius: 4px
		border-bottom-left-radius: 4px
		border-top-color: #ddd
		margin-top: -1px
		.ng-dropdown-panel-items .ng-option:last-child
			border-bottom-right-radius: 4px
			border-bottom-left-radius: 4px
	&.ng-select-top
		bottom: 100%
		border-top-right-radius: 4px
		border-top-left-radius: 4px
		border-bottom-color: #ddd
		margin-bottom: -1px
		.ng-dropdown-panel-items .ng-option:first-child
			border-top-right-radius: 4px
			border-top-left-radius: 4px
	.ng-dropdown-header
		border-bottom: 1px solid #ddd
		padding: 5px 7px
	.ng-dropdown-footer
		border-top: 1px solid #ddd
		padding: 5px 7px
	.ng-dropdown-panel-items
		margin-bottom: 1px
		.ng-optgroup
			-webkit-user-select: none
			-moz-user-select: none
			-ms-user-select: none
			user-select: none
			padding: 8px 10px
			font-weight: 500
			color: rgba(0, 0, 0, 0.54)
			cursor: pointer
			&.ng-option-disabled
				cursor: default
			&.ng-option-marked
				background-color: #ebf5ff
			&.ng-option-selected
				background-color: #f5faff
				font-weight: 600
		.ng-option
			background-color: #fff
			color: rgba(0, 0, 0, 0.87)
			padding: 8px 10px
			white-space: normal !important
			&.ng-option-selected
				color: #666
				background-color: #f5faff
				.ng-option-label
					font-weight: 600
			&.ng-option-marked
				background-color: #ebf5ff
				color: #666
			&.ng-option-disabled
				color: #cccccc !important
			&.ng-option-child
				padding-left: 22px
			.ng-tag-label
				padding-right: 5px
				font-size: 80%
				font-weight: 400

.ng-select-disabled, .ng-select-disabled-tr, .ng-select-disabled-tile
	background-color: #f5f5f5
	pointer-events: none
	.ng-select-container
		pointer-events: none
		.ng-value-container
		.ng-value
			z-index: 1
			padding-left: 10px

.ng-select-disabled-tr, .ng-select-disabled-tile
	background-color: transparent
.ng-select
	.ng-select-container
		background-color: #f5f5f5 !important
.form__group-item
	input
		background-color: #f5f5f5

label.checkbox-label:before
	background-color: #f5f5f5 !important
