.sidebar
  height: 100%
  width: 0
  position: fixed
  z-index: 1000
  top: 0
  right: 0
  overflow-x: hidden
  transition: 0.2s
  background-color: #effcff
  &--open
    width: 480px
    border: 1px solid #e1e1e8
    box-shadow: -10px 0 19px 0 rgba(0, 0, 0, 0.2)
  &--close
    width: 0

.sidebar__title
  width: 100%
  padding: 25px 0 20px 25px
  border-bottom: 1px solid #ccc
  font-size: 1.5em

.sidebar__group
  padding-left: 25px
  padding-top: 20px
  width: 410px
  .sidebar__group-input
    position: relative
  &--double
    display: flex
    justify-content: space-between
    .sidebar__group-item
      width: 196px
      position: relative
      .sidebar__input-close-button
        top: 30px
    .sidebar__checkbox
      padding-top: 27px
  &--double-filter
    display: flex
    .sidebar__group-item
      width: 196px
      margin-right: 20px
  &--triple
    display: flex
    justify-content: space-between
    .sidebar__group-item
      width: 122px

  &--triple-filter
    display: flex
    .sidebar__group-item
      width: 122px
      margin-right: 22px

  &--fourth-filter
    display: flex
    .sidebar__group-item
      width: 87px
      margin-right: 22px

  &--padding-bottom-5
    padding-bottom: 5px
  &--hide
    display: none

.sidebar__group-checkbox
  margin-left: 15px

.sidebar__label-wrapper
  height: 30px
  width: 100%
  line-height: 2.2
  cursor: pointer
  margin-bottom: 5px
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12)
  transition: all 0.2s cubic-bezier(.25,.8,.25,1)
  &:hover
    .sidebar__filter-icon
      opacity: 1

.sidebar__label-wrapper
  height: 30px
  width: 100%
  line-height: 2.2
  cursor: pointer
  margin-bottom: 5px
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12)
  transition: all 0.2s cubic-bezier(.25,.8,.25,1)
  &:hover
    .sidebar__filter-icon
      opacity: 1

.sidebar__label, .sidebar__input
  color: #6d6e7a
  font: 14px "SF-medium"
  &--common
    font-size: 14px
  &--filter
    cursor: pointer
  &--error
    margin-top: 7px
    display: block
    color: #a94442

.input__password-error
  outline: none !important
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important
  border-color: #a94442

.sidebar__group-input
  select
    padding: 6.5px 10px

.sidebar__input
  font-size: $fsize-m
  color: #30334d
  padding: 9px 10px
  &--date[readonly]
    background: #fcfcfc
  &--date-modal[readonly]
    background: #fcfcfc
  &--date
    width: 162px
    padding: .5em .6em
    font-family: Arial,sans-serif
  &--date-modal
    width: inherit
  &--alias
    width: 88%

.sidebar__checkbox
  label.checkbox-label:before
    margin-right: 10px

.sidebar__radiobox
  margin: 5px 0
  line-height: 1.8
  label
    font-weight: 500
  .radiobox-label:before
    margin-right: 5px

.sidebar__input-close-button, .sidebar__input-eye
  display: none
  font-size: 16px
  color: #adaeb6
  background-color: #efeff4
  border: none
  outline: none
  border-radius: 100%
  cursor: pointer
  opacity: 1
  position: absolute
  top: 7px
  right: 5px
  &--hide
    display: none

.sidebar__input-eye
  display: block
  opacity: 0.8
  background: transparent
  .fa
    font-family: FontAwesome
  .fa-eye:before
    content: '\f06e'
  &--hide
    opacity: 1
    .fa-eye:before
      content: '\f070'
  &:hover
    opacity: 1

.sidebar__input-alias-add
  position: absolute
  right: 0
  top: 0
  opacity: 1
  &--disabled
    opacity: 0.6
  &--hide
    display: none

.sidebar__footer
  width: 100%
  display: flex
  justify-content: flex-start
  margin: 20px 0
  margin-top: 15px
  padding: 10px 0
  padding-left: 25px
  -webkit-box-shadow: 0 0 15px 0 rgba(150,147,150,1)
  -moz-box-shadow: 0 0 15px 0 rgba(150,147,150,1)
  box-shadow: 0 0 15px 0 rgba(150,147,150,1)
  .sidebar__footer-button
    font-weight: normal
    width: 196px
  .sidebar__footer-button:first-child
    margin-right: 20px

.sidebar__hide
  position: absolute
  top: 35px
  right: 15px
  border: 0
  background: 0
  outline: none
  cursor: pointer
  font-size: $fsize-l

.sidebar__filter-icon
  color: #cbcbcf
  cursor: pointer
  font-size: $fsize-l !important
  font-family: FontAwesome
  line-height: 2.5 !important
  width: 15px
  float: right
  opacity: 0.8
  &--plus:before
    content: '\f067'
  &--minus:before
    content: '\f068' !important

fieldset
  border: none
  padding: 0

fieldset[disabled]
  label.checkbox-label:before
    background: #ddd
  input, select
    background-color: #f5f5f5 !important
