.toast-close-button
	position: absolute
	font-size: 24px
	right: 10px
	top: 3px
	font-weight: bold
	color: $white
	opacity: 0.8
	&:hover
		opacity: 1
