.grid--reseller-users
	.grid__header
		grid-template-columns: 5% 20% 20% 25% 15% 15%
		-ms-grid-columns: 5% 20% 20% 25% 15% 15%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 20% 20% 25% 15% 15%
			-ms-grid-columns: 5% 20% 20% 25% 15% 15%

.grid--customers
	.grid__header
		grid-template-columns: 10% 15% 25% 10% 20% 20%
		-ms-grid-columns: 10% 15% 25% 10% 20% 20%
	.grid__body
		.grid__body-row
			grid-template-columns: 10% 15% 25% 10% 20% 20%
			-ms-grid-columns: 10% 15% 25% 10% 20% 20%

.grid--service-items
	.grid__header
		grid-template-columns: 14% 20% 14% 14% 14% 14% 10%
		-ms-grid-columns: 14% 20% 14% 14% 14% 14% 10%
	.grid__body
		.grid__body-row
			grid-template-columns: 14% 20% 14% 14% 14% 14% 10%
			-ms-grid-columns: 14% 20% 14% 14% 14% 14% 10%

.grid--service-items-global
	.grid__header
		grid-template-columns: 14% 20% 14% 14% 14% 14% 10%
		-ms-grid-columns: 14% 20% 14% 14% 14% 14% 10%
	.grid__body
		.grid__body-row
			grid-template-columns: 14% 20% 14% 14% 14% 14% 10%
			-ms-grid-columns: 14% 20% 14% 14% 14% 14% 10%

.grid--products
	.grid__header
		grid-template-columns: 5% 15% 20% 10% 15% 7% 7% 7% 7% 7%
		-ms-grid-columns: 5% 15% 20% 10% 15% 7% 7% 7% 7% 7%
.grid__body
	.grid__body-row
		grid-template-columns: 5% 15% 20% 10% 15% 7% 7% 7% 7% 7%
		-ms-grid-columns: 5% 15% 20% 10% 15% 7% 7% 7% 7% 7%

.grid--sites
	.grid__header
		grid-template-columns: 5% 20% 20% 15% 10% 10% 20%
		-ms-grid-columns: 5% 20% 20% 15% 10% 10% 20%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 20% 20% 15% 10% 10% 20%
			-ms-grid-columns: 5% 20% 20% 15% 10% 10% 20%

.grid--users
	.grid__header
		grid-template-columns: 20% 40% 40%
		-ms-grid-columns: 20% 40% 40%
	.grid__body
		.grid__body-row
			grid-template-columns: 20% 40% 40%
			-ms-grid-columns: 20% 40% 40%

.grid--feeds-main
	.grid__header
		grid-template-columns: 40% 30% 30%
		-ms-grid-columns: 40% 30% 30%
	.grid__body
		.grid__body-row
			grid-template-columns: 40% 30% 30%
			-ms-grid-columns: 40% 30% 30%

.grid--invoices
	.grid__header
		grid-template-columns: 5% 12% 18% 14% 14% 12% 13% 12%
		-ms-grid-columns: 5% 12% 18% 14% 14% 12% 13% 12%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 12% 18% 14% 14% 12% 13% 12%
			-ms-grid-columns: 5% 12% 18% 14% 14% 12% 13% 12%

.grid--invoices-period
	.grid__header
		grid-template-columns: 5% 10% 15% 25% 12% 9% 8% 8% 8%
		-ms-grid-columns: 5% 10% 15% 25% 12% 9% 8% 8% 8%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 10% 15% 25% 12% 9% 8% 8% 8%
			-ms-grid-columns: 5% 10% 15% 25% 12% 9% 8% 8% 8%

.grid--kit-products
	.grid__header
		grid-template-columns: 5% 20% 17% 17% 10% 10% 10% 10%
		-ms-grid-columns: 5% 20% 17% 17% 10% 10% 10% 10%
.grid__body
	.grid__body-row
		grid-template-columns: 5% 20% 17% 17% 10% 10% 10% 10%
		-ms-grid-columns: 5% 20% 17% 17% 10% 10% 10% 10%

.grid--transaction
	.grid__header
		grid-template-columns: 5% 15% 7% 8% 7% 9% 8% 8% 7% 9% 9% 8%
		-ms-grid-columns: 5% 15% 7% 8% 7% 9% 8% 8% 7% 9% 9% 8%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 15% 7% 8% 7% 9% 8% 8% 7% 9% 9% 8%
			-ms-grid-columns: 5% 15% 7% 8% 7% 9% 8% 8% 7% 9% 9% 8%

.grid--transaction-period
	.grid__header
		grid-template-columns: 33.3% 33.3% 33.3%
		-ms-grid-columns: 33.3% 33.3% 33.3%
	.grid__body
		.grid__body-row
			grid-template-columns: 33.3% 33.3% 33.3%
			-ms-grid-columns: 33.3% 33.3% 33.3%

.grid--usage
	.grid__header
		grid-template-columns: 15% 13% 13% 10% 8% 8% 8% 10% 15%
		-ms-grid-columns: 15% 13% 13% 10% 8% 8% 8% 10% 15%
	.grid__body
		.grid__body-row
			grid-template-columns: 15% 13% 13% 10% 8% 8% 8% 10% 15%
			-ms-grid-columns: 15% 13% 13% 10% 8% 8% 8% 10% 15%

.grid--assignments
	.grid__header
		grid-template-columns: 5% 15% 16% 15% 15% 15% 15%
		-ms-grid-columns: 5% 15% 16% 15% 15% 15% 15%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 15% 16% 15% 15% 15% 15%
			-ms-grid-columns: 5% 15% 16% 15% 15% 15% 15%

.grid--users
	.grid__header
		grid-template-columns: 5% 33% 34% 14% 14%
		-ms-grid-columns: 5% 33% 34% 14% 14%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 33% 34% 14% 14%
			-ms-grid-columns: 5% 33% 34% 14% 14%

.grid--product-category
	.grid__header
		grid-template-columns: 5% 15% 20% 10%
		-ms-grid-columns: 5% 15% 20% 10%
.grid__body
	.grid__body-row
		grid-template-columns: 5% 15% 20% 10%
		-ms-grid-columns: 5% 15% 20% 10%

.grid--assignments-rating
	.grid__header
		grid-template-columns: 5% 50% 45%
		-ms-grid-columns: 5% 50% 45%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 50% 45%
			-ms-grid-columns: 5% 50% 45%

.grid--period
	.grid__header
		grid-template-columns: 25% 25% 25% 25%
		-ms-grid-columns: 25% 25% 25% 25%
	.grid__body
		.grid__body-row
			grid-template-columns: 25% 25% 25% 25%
			-ms-grid-columns: 25% 25% 25% 25%

.grid--product-templates
	.grid__header
		grid-template-columns: 5% 35% 36% 12% 12%
		-ms-grid-columns: 5% 35% 36% 12% 12%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 35% 36% 12% 12%
			-ms-grid-columns: 5% 35% 36% 12% 12%

.grid--global-rating
	.grid__header
		grid-template-columns: 5% 25% 25% 25% 20%
		-ms-grid-columns: 5% 25% 25% 25% 20%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 25% 25% 25% 20%
			-ms-grid-columns: 5% 25% 25% 25% 20%

.rating__icon
	position: absolute
	opacity: 0.8
	&--rc
		width: 23px
		height: 21px
		margin-top: 7px
	&--pb
		width: 23px
		height: 25px
		margin-top: 5px
	&--bundle
		width: 35px
		height: 35px
		margin-left: -3px

.grid--email-templates
	.grid__header
		grid-template-columns: 30% 30% 30% 10%
		-ms-grid-columns: 30% 30% 30% 10%
		border-top: 1px solid transparent
	.grid__body
		.grid__body-row
			grid-template-columns: 30% 30% 30% 10%
			-ms-grid-columns: 30% 30% 30% 10%
			margin: 2.5px 5px
	.grid__footer
		display: none

.grid--global-bundle
	.grid__header
		grid-template-columns: 5% 20% 25% 20% 20% 10%
		-ms-grid-columns: 5% 20% 25% 20% 20% 10%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 20% 25% 20% 20% 10%
			-ms-grid-columns: 5% 20% 25% 20% 20% 10%

.grid--global-bundle-r
	.grid__header
		grid-template-columns: 5% 20% 20% 15% 15% 10% 15%
		-ms-grid-columns: 5% 20% 20% 15% 15% 10% 15%
.grid__body
	.grid__body-row
		grid-template-columns: 5% 20% 20% 15% 15% 10% 15%
		-ms-grid-columns: 5% 20% 20% 15% 15% 10% 15%

.grid--pricebook
	.grid__header
		grid-template-columns: 5% 28% 28% 15% 10% 14%
		-ms-grid-columns: 5% 28% 28% 15% 10% 14%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 28% 28% 15% 10% 14%
			-ms-grid-columns: 5% 28% 28% 15% 10% 14%

.grid--pricebook-insert
	.grid__header
		grid-template-columns: 5% 35% 35% 12.5% 12.5%
		-ms-grid-columns: 5% 35% 35% 12.5% 12.5%
	.grid__body
		.grid__body-row
			grid-template-columns: 5% 35% 35% 12.5% 12.5%
			-ms-grid-columns: 5% 35% 35% 12.5% 12.5%

.grid--period-transaction
	.grid
		margin-top: 0
		.grid__header
			grid-template-columns: 130px 130px 130px 145px
			-ms-grid-columns: 130px 130px 130px 145px
		.grid__body
			.grid__body-row
				grid-template-columns: 130px 130px 130px 145px
				-ms-grid-columns: 130px 130px 130px 145px
