.form
	overflow: hidden
	width: 1024px
	margin: 0 auto
	&--report
		width: 650px
		overflow: initial
		.md-drppicker
			right: 67px !important
	&--email
		width: 395px
		.form__group .form__group-item--x1
			padding-right: 20px
			width: 33%
		.form__group-item--x2
			width: 200px !important
	&--import, &--export
		width: 100%
		h4, label
			font-weight: normal
		.form__content, .form__group
			margin: 0
			justify-content: center
		.form__bottom
			padding-top: 5px
			margin: 10px auto
			border: none
			justify-content: center !important
		.radiobox
			margin: 5px auto
			line-height: 1.8
		.radiobox-label:before
			margin: 1px 5px
	&--export
		.radiobox
			text-align: left
		.form__group-item--500, .form__group-item--500-fs, .form__group-item--500-sb, .form__group-item--800, .form__group-item--1000-fs, .form__group-item--1200-fs
			margin: 10px auto
			width: 500px
			position: relative
			display: flex
			justify-content: space-between
			.form__group-input
				padding-left: 5px
			p
				font-size: 16px
				opacity: 0.8
				color: #6d6e7a
				margin: 0
				line-height: 2
				width: 45%
				text-align: left
				word-break: break-all
			select
				width: 200px
			.ng-select, .form__group-input
				width: 56%
				text-align: left
				.ng-value, .ng-input input
					padding-left: 5px
			.form__group-item-custom1
				display: flex
				width: 355px
				justify-content: space-between
				margin-bottom: 10px
				.custom-input
					width: 100%
					line-height: 24px
				p
					width: 40%
					text-align: left
				&:last-child
					margin-bottom: 0
			.checkbox
				margin-left: 30px
				margin-top: 3px

		.form__group-item--800
			width: 800px
			p
				width: 80%
				margin-right: 10px
		.form__group-item--1000-fs
			width: 1000px
		.form__group-item--1200-fs
			width: 1200px
		.form__group-item--500-fs, .form__group-item--500-sb, .form__group-item--1000-fs, .form__group-item--1200-fs
			justify-content: flex-start
			p
				text-align: left
				&:nth-child(1)
					width: 145px
				&:nth-child(2)
					width: 158px
				&:nth-child(3)
					width: 200px
			&:first-child
				p
					&:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5)
						text-align: left
						font-size: 14px
						color: inherit
						opacity: 1
						line-height: 2.3
		.form__group-item--500-fs
			.ng-select
				width: 40%
		.form__group-item--500-sb
			justify-content: space-between
			p
				&:nth-child(2)
					width: 200px
		.form__group-item--1200-fs
			.ng-select
				width: 20%
				&:nth-child(5)
					margin: 0px 5%
			p
				&:nth-child(1)
					width: 200px
				&:nth-child(2)
					width: 200px
				&:nth-child(3)
					width: 200px
			&:first-child
				p
					&:nth-child(4), &:nth-child(5)
						width: 25%
		.form__group-item--1000-fs,
		p
			word-break: break-word
			&:nth-child(1)
				width: 150px
				&:nth-child(2)
					width: 300px
				&:nth-child(3)
					width: 300px
			.ng-select
				width: 250px
			// describe hehader columns
			&:first-child
				p
					&:nth-child(4)
						width: 250px

	&--integration
		width: 100%
		.form__title
			position: relative
			display: flex
			font-size: 20px
			img
				position: absolute
				top: 0
				right: 0
				width: 7.3%
		.form__bottom
			margin-left: 0
			margin-top: 5px
			&.integration__bottom
				margin-right: 0
				margin-bottom: 0
				border: none
				.btn--secondary
					width: auto !important

.form__title
	padding: 13px 0
	padding-left: 25px
	border-bottom: 1px solid rgba(0, 0, 0, 0.1)
	margin: auto
	text-align: left
	font: 24px "SF-regular"

.form__provision
	-webkit-backface-visibility: hidden
	transition: all .4s cubic-bezier(0.645, 0.045, 0.355, 1)
	position: absolute
	width: 700px
	margin-left: -350px
	left: 50%
	&--inactive
		transform: rotateY(90deg)
	.form__group-item--x2
		width: 300px !important

.form__content
	width: 100%
	margin: 20px 0
	.form__content-title
		text-align: center
		&--subtitle
			color: #6d6e7a
			font-size: 22px
		&--subtitle-notification
			font-size: 16px
			display: block
			opacity: 0.7

.form__group
	display: flex
	margin: 15px auto
	&-wrapper, &-wrapper-np
		padding: 15px
		border: 1px dashed #ddd
		border-radius: 3px
	&-wrapper-np
		padding: 0 15px
	&--service
		.ng-select
			.ng-value
				padding-left: 12px
	.form__group-item--x1
		padding-right: 15px
		width: 28%
		text-align: right
		&--report
			width: 23%
		.saved
			.spinner-align
				margin: 4px 0
				margin-left: -30px
				position: absolute
			.fa-circle-o-notch:before
				color: $primary-color
				font-size: 1.2em
	.form__group-item--x2
		width: 400px
		&--double
			display: flex
			width: 400px
			.form__group-input
				width: 127px
			.form__group-label
				padding-left: 4.15em
				margin-right: 15px
				&--report
					padding-left: 1.3em
			.radiobox
				width: 100px
				margin: 4px 0
				line-height: 1.7
			.checkbox
				margin-left: 5.7em
				margin-top: 3px
				.checkbox-label
					line-height: 1.8
		&--report
			width: 300px
		&--btn-middle
			margin: 0 auto
			width: inherit

	input, select
		font-size: $fsize-m

	&--mydetails-last-item
		margin-top: 35px
		margin-bottom: 30px
	&--customize
		.form__bottom
			margin: 0
			width: 250px
			padding-top: 25px
			justify-content: center
			.btn
				width: 140px
				margin: 0

	&--header
		width: 500px
		.colour__item
			width: 100%
			background-color: white
			border-bottom: 2px solid rgb(53, 152, 219)
			color: #7C7C7C
			.colour__group
				display: flex
			.colour__logo
				width: 110px
				display: flex
				justify-content: center
				img
					max-height: 49px
					width: 100%
			.colour__header
				border-left: 1px solid lightgray
				border-right: 1px solid lightgray
				ul
					list-style: none
					position: relative
					margin: 0
					padding: 0
					li
						position: relative
						padding: 13px 14px
						z-index: 222
					li:hover > ul
						display: block
					ul
						display: none
						position: absolute
						top: 100%
						left: -42.1px
						background-color: indianred
						color: white
						li
							float: none
							width: 100px
							&:hover
								background: lightcoral

			.colour__header-item
				padding: 13px 14px
				position: relative
				display: inline-block
				cursor: pointer
				&:hover
					background-color: indianred
					color: white
			.colour__header-triangle
				opacity: 1
				bottom: 0
				content: " "
				height: 0
				width: 0
				left: 50%
				margin-left: -5px
				position: absolute
				border-left: solid transparent 4px
				border-right: solid transparent 4px
				border-bottom: solid #3598db 4px

.colour
	padding-right: 20px
	border-right: 1px dashed rgba(0, 0, 0, 0.1)
	&:nth-child(2)
		padding-left: 20px
	&:last-child
		padding: 0
		border: none
		padding-left: 20px
	.color__item-logo
		display: flex
		justify-content: center
		padding: 20px 10px
		.image-1
			width: auto
			max-height: 80px

.colour__item
	display: flex
	justify-content: space-between
	width: 190px
	margin: 8px 0
	.colour__item-input
		width: 30px
		height: 30px
		input
			border-radius: 0
			cursor: pointer

.colour__item-label
	line-height: 2.3
	color: #7C7C7C

.form__group-label, .checkbox-label
	font-weight: 500
	color: #6d6e7a
	line-height: 2.3
	&--error
		color: indianred

.form__description
	text-align: left
	padding-left: 15px
	line-height: 2.2
	font-size: .9em
	width: auto
	&--warning
		color: $color-warning
	.fa-circle-o-notch:before
		color: $primary-color

.form__bottom
	display: flex
	justify-content: center
	margin: 20px auto
	margin-bottom: 30px
	margin-left: -23px
	border-top: 1px solid #cacacd
	padding-top: 20px
	.btn--details-save, .btn--details-discard
		padding: .5em 2em
		font-size: inherit
		margin: 0
		width: 176px
	.btn--details-save
		margin-left: 5px
	.btn--details-discard
		margin-left: 48px
	&--border-none
		border: none

.charge__row, .charge__row-table
	display: flex
	justify-content: space-between
	padding: 8px 0
	border-radius: 3px
	cursor: pointer
	margin: 3px 0
	width: 100%
	.charge__row-action
		opacity: 0 !important
	&:hover
		transition: all 0.2s
		box-shadow: 0 6px 3px -6px rgba(0, 0, 0, 0.1)
		.charge__row-action
			opacity: 0.7 !important

.charge__row-group, .charge__row-group-table
	&:nth-child(1)
		word-break: break-all
		width: 20%
	&:nth-child(2)
		display: flex
		width: 65%
	&:nth-child(3)
		margin-left: 10px
		width: 17%
	.charge__row-item
		color: #6d6e7a
		word-break: break-word
		&:nth-child(2)
			opacity: 0.7
			font-size: 13px
			line-height: 1.7
			word-break: break-word
	&--units
		flex-direction: column
		.charge__row-item-header,
		.charge__row-item-bottom
			display: grid
			grid-template-columns: 20% 20% 20% 20% 20%
			text-align: right
			span
				margin: 0 2px
				font-size: 13px
				line-height: 1.7
				word-break: break-word
		.charge__row-item-bottom
			opacity: 0.7
	&--data
		.charge__row-item
			display: grid
			width: 33.3%
			span
				text-align: right
				&:last-child
					opacity: 0.7
					color: #6d6e7a
			&--full
				width: 100%
			&:nth-child(2)
				opacity: 1
				font-size: 14px

.charge__row-table
	margin: 0
	padding: 0
	&:hover
		-webkit-box-shadow: none
		-moz-box-shadow: none
		box-shadow: none

.charge__row-group-table
	&:nth-child(1)
		word-break: break-all
		width: 100%
		margin-right: 5px
		.charge__row-item
			width: 100%
			&:nth-child(1)
				color: #1f2125
			&:nth-child(2)
				line-height: inherit
				font-size: 14px
	&:nth-child(2), &:nth-child(3)
		width: 0
